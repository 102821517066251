import { GridSortDirection } from '@mui/x-data-grid';
import { CountryCode } from 'libphonenumber-js';

import { SelectOption } from '@/components/common/form/Select/CommonSelect';
import { ErrorCode } from '@/openapi-api/member-service';

export const allowedCountries: CountryCode[] = ['CA', 'US'];

export const sortingOrder = [
  {
    label: 'Lowest to highest',
    value: 'asc' as GridSortDirection,
  },
  {
    label: 'Highest to lowest',
    value: 'desc' as GridSortDirection,
  },
];

export const genderOptions: SelectOption[] = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Others',
    value: 'OTHER',
  },
];

export const roleOptions: SelectOption[] = [
  {
    label: 'Member',
    value: 'MEMBER',
  },
  {
    label: 'Guest',
    value: 'GUEST',
  },
];

export const ErrorMessage: Record<ErrorCode, string> = {
  [ErrorCode.MEMBER_TIER_LEVEL_IS_SET]: 'This tier has already been set',
  [ErrorCode.MEMBER_TIER_LEVEL_IS_HIGHER]:
    'The tier of the reward is lower than the current tier',
  [ErrorCode.CONSUMER_PHONE_INVALID_UPDATE]: 'Phone number invalid update',
  [ErrorCode.CONSUMER_EMAIL_INVALID_UPDATE]: 'Email invalid update',
  [ErrorCode.CONSUMER_EMAIL_EXPIRED_CODE]: 'Code is expired',
  [ErrorCode.CONSUMER_PHONE_PLIVO_INCORRECT_OTP]: 'Incorrect verify code',
  [ErrorCode.CONSUMER_PHONE_PLIVO_EXCEEDED_ATTEMPTS]:
    'You have reached the limit for verification code requests. Please contact support for assistance.',
  [ErrorCode.CONSUMER_PHONE_PLIVO_EXPIRED_SESSION]:
    'You have reached the limit for verification code requests. Please contact support for assistance.',
  [ErrorCode.BUSINESS_USER_EMAIL_INVALID_UPDATE]: 'Email invalid update',
  [ErrorCode.BUSINESS_USER_EMAIL_EXPIRED_CODE]: 'Code is expired',
  [ErrorCode.NO_AVAILABLE_PHONE_NUMBERS_FOUND]:
    'No available phone numbers found',
  [ErrorCode.PHONE_NUMBER_CREATE_FAILED]: 'Phone number create failed',
  [ErrorCode.PHONE_NUMBER_UNRENT_FAILED]: 'Phone number unrent failed',
  [ErrorCode.BUSINESS_CAMPAIGN_SUBSCRIPTION_PLAN]:
    'Please upgrade your subscription plan to send more campaigns',
  [ErrorCode.BUSINESS_CAMPAIGN_LIMIT]:
    'You have reached the limit of campaigns',
  [ErrorCode.BUSINESS_CAMPAIGN_INCORRECT_STATUS_UPDATE]:
    'Incorrect status update',
  [ErrorCode.BUSINESS_CAMPAIGN_COMPLETED]: 'Campaign is completed',
  [ErrorCode.BUSINESS_COLLABORATION_DISABLED]: 'Collaboration is disabled',
  [ErrorCode.BUSINESS_USES_DEFAULT_POINTS]:
    'Business uses default points system',
  [ErrorCode.CONSUMER_PHONE_PLIVO_NOT_EQUAL_REQUESTED_ONE]:
    'Plivo not equal requested one',
  [ErrorCode.CONSUMER_PHONE_PLIVO_NOT_VERIFIED]: 'Plivo not verified',
  [ErrorCode.BUSINESS_CAMPAIGN_NOT_DELETABLE]: 'Campaign not deletable',
  [ErrorCode.BUSINESS_OFFER_STATUS]: 'Offer status',
  [ErrorCode.BUSINESS_OFFER_TYPE]: 'BUSINESS_OFFER_TYPE',
  [ErrorCode.BUSINESS_OFFER_MEMBER_MATCH]: 'BUSINESS_OFFER_MEMBER_MATCH',
  [ErrorCode.BUSINESS_OFFER_MEMBER_MISSING]: 'BUSINESS_OFFER_MEMBER_MISSING',
  [ErrorCode.BUSINESS_OFFER_STATUS_TRANSITION]:
    'BUSINESS_OFFER_STATUS_TRANSITION',
  [ErrorCode.BUSINESS_DEFAULT_POINTS_MISSING]:
    'BUSINESS_DEFAULT_POINTS_MISSING',
  [ErrorCode.BUSINESS_POINTS_REQUIRED]: 'BUSINESS_POINTS_REQUIRED',
  [ErrorCode.BUSINESS_POINTS_NOT_UNIQUE]: 'BUSINESS_POINTS_NOT_UNIQUE',
  [ErrorCode.REFERRAL_PROGRAM_EXISTS]: 'REFERRAL_PROGRAM_EXISTS',
  [ErrorCode.BUSINESS_REWARD_DISCOUNT_CODES_CSV]:
    'BUSINESS_REWARD_DISCOUNT_CODES_CSV',
  [ErrorCode.BUSINESS_REWARD_CLAIM_BALANCE]: 'BUSINESS_REWARD_CLAIM_BALANCE',
  [ErrorCode.BUSINESS_REWARD_CLAIM_MEMBERSHIP]:
    'BUSINESS_REWARD_CLAIM_MEMBERSHIP',
  [ErrorCode.BUSINESS_REWARD_CLAIM_COMPLETED]:
    'BUSINESS_REWARD_CLAIM_COMPLETED',
  [ErrorCode.BUSINESS_REWARD_CLAIM_DURATION]: 'BUSINESS_REWARD_CLAIM_DURATION',
  [ErrorCode.BUSINESS_REWARD_CLAIM_GENDER]: 'BUSINESS_REWARD_CLAIM_GENDER',
  [ErrorCode.BUSINESS_REWARD_CLAIM_TIER]: 'BUSINESS_REWARD_CLAIM_TIER',
  [ErrorCode.BUSINESS_REWARD_CLAIM_AGE]: 'BUSINESS_REWARD_CLAIM_AGE',
  [ErrorCode.BUSINESS_REWARD_STATUS_TRANSITION]:
    'BUSINESS_REWARD_STATUS_TRANSITION',
  [ErrorCode.BUSINESS_REWARD_STATUS]: 'BUSINESS_REWARD_STATUS',
  [ErrorCode.BUSINESS_REWARD_TYPE]: 'BUSINESS_REWARD_TYPE',
  [ErrorCode.BUSINESS_REWARD_LIMIT]: 'BUSINESS_REWARD_LIMIT',
  [ErrorCode.BUSINESS_REWARD_LIMIT_REDUCED]: 'BUSINESS_REWARD_LIMIT_REDUCED',
  [ErrorCode.BUSINESS_REWARD_LIMIT_DISCOUNT_CODES]:
    'BUSINESS_REWARD_LIMIT_DISCOUNT_CODES',
  [ErrorCode.BUSINESS_REWARD_DISCOUNT_CODES]: 'BUSINESS_REWARD_DISCOUNT_CODES',
  [ErrorCode.BUSINESS_REWARD_DURATION]: 'BUSINESS_REWARD_DURATION',
  [ErrorCode.BUSINESS_TIER_LEVEL_ID]: 'BUSINESS_TIER_LEVEL_ID',
  [ErrorCode.BUSINESS_TIER_CONFIGURATION_EXISTS]:
    'BUSINESS_TIER_CONFIGURATION_EXISTS',
  [ErrorCode.BUSINESS_TIER_CONFIGURATION_STATUS]:
    'BUSINESS_TIER_CONFIGURATION_STATUS',
  [ErrorCode.BUSINESS_CAMPAIGN_SMS_PHONE_NUMBER_NOT_SET]:
    'BUSINESS_CAMPAIGN_SMS_PHONE_NUMBER_NOT_SET',
  [ErrorCode.CONSUMER_NOT_FOUND]: 'CONSUMER_NOT_FOUND',
  [ErrorCode.BUSINESS_NOT_FOUND]: 'BUSINESS_NOT_FOUND',
  [ErrorCode.BUSINESS_OFFER_CLAIMED]: 'BUSINESS_OFFER_CLAIMED',
  [ErrorCode.BUSINESS_REWARD_CLAIMED]: 'BUSINESS_REWARD_CLAIMED',
  [ErrorCode.BUSINESS_REWARD_CLAIM_STATUS]: 'BUSINESS_REWARD_CLAIM_STATUS',
};

export const TERMS_AND_CONDITION_URL = 'https://ostana.io/terms-conditions/';
export const PRIVACY_POLICY_URL = 'https://ostana.io/privacy-policy/';
export const OSTANA_MAIN_SITE = 'https://ostana.io/';
export const PEXELS_URL = 'https://images.pexels.com/';
export const DEFAULT_BUSINESS_COVER = '/images/businessInfoCoverDefault.png';
export const REDIRECT_URL_KEY = 'redirectUrl';
export const REFERRAL_CODE = 'referral-code';
export const AUTO_JOIN = 'auto-join';
